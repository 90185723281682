<template>
  <div v-loading="loading" class="editor">
    <transition name="fade" mode="out-in">
      <el-progress
        v-if="uploading"
        :text-inside="true"
        :stroke-width="24"
        :percentage="uploadProgress"
        style="margin-bottom: 24px;"
      ></el-progress>
    </transition>
    <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
      <div class="menubar">
        <el-button-group>
          <el-button
            class="bold-button"
            :class="{ 'is-active': isActive.bold() }"
            size="mini"
            icon="fal fa-bold"
            @click="commands.bold"
          ></el-button>
          <el-button
            :class="{ 'is-active': isActive.italic() }"
            size="mini"
            icon="fal fa-italic"
            @click="commands.italic"
          ></el-button>
          <!-- <el-button
            :class="{ 'is-active': isActive.paragraph() }"
            size="mini"
            icon="fal fa-paragraph"
            @click="commands.paragraph"
          ></el-button> -->
          <el-button
            v-if="allowHeadings"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            size="mini"
            icon="fal fa-h2"
            @click="commands.heading({ level: 2 })"
          ></el-button>
          <el-button
            v-if="allowHeadings"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            size="mini"
            icon="fal fa-h3"
            @click="commands.heading({ level: 3 })"
          ></el-button>
          <el-button
            v-if="allowLists"
            :class="{ 'is-active': isActive.bullet_list() }"
            size="mini"
            icon="fal fa-list-ul"
            @click="commands.bullet_list"
          ></el-button>
          <el-button
            v-if="allowLists"
            :class="{ 'is-active': isActive.ordered_list() }"
            size="mini"
            icon="fal fa-list-ol"
            @click="commands.ordered_list"
          ></el-button>
          <el-button
            v-if="allowImages"
            size="mini"
            icon="fal fa-image"
            @click="showImagePrompt(commands.image)"
          ></el-button>

          <el-button
            size="mini"
            icon="fal fa-undo"
            @click="commands.undo"
          ></el-button>
          <el-button
            size="mini"
            icon="fal fa-redo"
            @click="commands.redo"
          ></el-button>
        </el-button-group>
      </div>
    </editor-menu-bar>

    <editor-menu-bubble
      v-slot="{ commands, isActive, getMarkAttrs, menu }"
      class="menububble"
      :editor="editor"
      @hide="hideLinkMenu"
    >
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom - 80}px;`"
      >
        <form
          v-if="linkMenuIsActive"
          class="menububble__form"
          @submit.prevent="setLinkUrl(commands.link, linkUrl)"
        >
          <input
            ref="linkInput"
            v-model="linkUrl"
            class="menububble__input"
            type="text"
            placeholder="https://"
            @keydown.esc="hideLinkMenu"
          />
          <el-button
            v-if="isActive.link()"
            size="mini"
            icon="fal fa-trash-alt"
            @click="setLinkUrl(commands.link, null)"
          >
          </el-button>
          <el-button
            v-else
            size="mini"
            icon="fal fa-plus"
            @click="setLinkUrl(commands.link, linkUrl)"
          >
          </el-button>
        </form>

        <template v-else>
          <el-button
            :class="{ 'is-active': isActive.link() }"
            size="mini"
            icon="fal fa-link"
            @click="showLinkMenu(getMarkAttrs('link'))"
          >
            {{ isActive.link() ? "Link ändern" : "Link hinzufügen" }}
          </el-button>
        </template>
      </div>
    </editor-menu-bubble>

    <editor-content class="editor__content" :editor="editor"></editor-content>
    <input
      ref="fileInput"
      class="file-input"
      type="file"
      accept="image/png,image/jpeg"
      @change="handleChosenImage"
    />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap"
import {
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  History,
  HardBreak,
  Placeholder
} from "tiptap-extensions"
import Image from "@/utils/text-editor/Image"
import EditorMenuBubble from "@/utils/text-editor/EditorMenuBubble"
import { DirectUpload } from "@rails/activestorage"

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble
  },
  props: {
    value: {
      validator: prop =>
        typeof prop === "string" || prop === null || prop === undefined,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    allowImages: {
      type: Boolean,
      default: false
    },
    allowHeadings: {
      type: Boolean,
      default: true
    },
    allowLists: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      uploading: false,
      uploadProgress: 0,
      imgCommand: null,
      editor: null,
      linkUrl: null,
      linkMenuIsActive: false
    }
  },

  computed: {},
  watch: {
    loading: function(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.editor.setContent(this.value)
      }
    }
  },
  created() {
    let extensions = [
      new Heading({ levels: [2, 3] }),
      new OrderedList(),
      new BulletList(),
      new ListItem(),
      new Bold(),
      new Italic(),
      new Link(),
      new History(),
      new HardBreak(),
      new Placeholder({
        emptyNodeClass: "is-empty",
        emptyNodeText: "Schreiben Sie etwas …",
        showOnlyWhenEditable: true
      }),
      new Image()
    ]

    this.editor = new Editor({
      extensions: extensions,
      disablePasteRules: true, // disable Markdown when pasting
      disableInputRules: true, // disable Markdown when typing
      content: ``,
      onUpdate: ({ getHTML }) => {
        const newContent = getHTML()
        this.$emit("input", newContent)
      }
    })
  },
  mounted() {
    if (window.Cypress) {
      window.TextEditor = this
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      // Warn about properly adding downloads to articles
      if (url && url.includes("digitaloceanspaces.com")) {
        this.$confirm(
          "Bitte lesen Sie die <a href='https://www.notion.so/studionomai/Wie-kann-ich-einen-Download-zu-einem-Artikel-hinzuf-gen-5325b70faf6f46c784a9b3fc13abd553' target='blank'>Anleitung</a> zum korrekten Hinzufügen von Downloads in Artikeln oder Neuigkeiten.",
          "Fehlerhaft eingefügter Download Link",
          {
            dangerouslyUseHTMLString: true,
            showConfirmButton: false,
            cancelButtonText: "Schließen",
            type: "error"
          }
        )
          .then(() => {
            this.hideLinkMenu()
            this.editor.focus()
          })
          .catch(() => {
            this.hideLinkMenu()
            this.editor.focus()
          })
        return
      }
      command({ href: url })
      this.hideLinkMenu()
      this.editor.focus()
    },
    showImagePrompt(command) {
      this.imgCommand = command
      this.$refs.fileInput.click()
    },
    handleChosenImage(event) {
      let file = event.target.files[0]
      const url = `${process.env.VUE_APP_ROOT_API}/direct_uploads`

      const upload = new DirectUpload(file, url, {
        directUploadWillCreateBlobWithXHR: xhr => {
          // Put my JWT token in the auth header here
          xhr.setRequestHeader(
            "Authorization",
            `Bearer ${this.$store.state.auth.user.access_token}`
          )
          // Send progress upload updates
          xhr.upload.addEventListener("progress", event =>
            this.directUploadProgress(event)
          )
        }
      })
      this.uploading = true
      upload.create((error, blob) => {
        if (error) {
          // Handle the error
        } else {
          this.$refs.fileInput.value = null
          this.$emit("image-uploaded", {
            signed_id: blob.signed_id,
            command: this.imgCommand
          })
          this.uploading = false
        }
      })
    },
    directUploadProgress(event) {
      this.uploadProgress = (event.loaded / event.total) * 100
    }
  }
}
</script>

<style lang="scss" scoped>
.editor {
  position: relative;
  width: 100%;
  border: 1px solid #dcdfe6;
  padding: 24px 24px;
  margin-bottom: 40px;
  border-radius: 4px;

  .editor__content {
    word-wrap: break-word;
  }
}

.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  background: #444;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: white;
  }
}

.file-input {
  display: none;
}
</style>
