var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"editor"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.uploading)?_c('el-progress',{staticStyle:{"margin-bottom":"24px"},attrs:{"text-inside":true,"stroke-width":24,"percentage":_vm.uploadProgress}}):_vm._e()],1),_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menubar"},[_c('el-button-group',[_c('el-button',{staticClass:"bold-button",class:{ 'is-active': isActive.bold() },attrs:{"size":"mini","icon":"fal fa-bold"},on:{"click":commands.bold}}),_c('el-button',{class:{ 'is-active': isActive.italic() },attrs:{"size":"mini","icon":"fal fa-italic"},on:{"click":commands.italic}}),(_vm.allowHeadings)?_c('el-button',{class:{ 'is-active': isActive.heading({ level: 2 }) },attrs:{"size":"mini","icon":"fal fa-h2"},on:{"click":function($event){return commands.heading({ level: 2 })}}}):_vm._e(),(_vm.allowHeadings)?_c('el-button',{class:{ 'is-active': isActive.heading({ level: 3 }) },attrs:{"size":"mini","icon":"fal fa-h3"},on:{"click":function($event){return commands.heading({ level: 3 })}}}):_vm._e(),(_vm.allowLists)?_c('el-button',{class:{ 'is-active': isActive.bullet_list() },attrs:{"size":"mini","icon":"fal fa-list-ul"},on:{"click":commands.bullet_list}}):_vm._e(),(_vm.allowLists)?_c('el-button',{class:{ 'is-active': isActive.ordered_list() },attrs:{"size":"mini","icon":"fal fa-list-ol"},on:{"click":commands.ordered_list}}):_vm._e(),(_vm.allowImages)?_c('el-button',{attrs:{"size":"mini","icon":"fal fa-image"},on:{"click":function($event){return _vm.showImagePrompt(commands.image)}}}):_vm._e(),_c('el-button',{attrs:{"size":"mini","icon":"fal fa-undo"},on:{"click":commands.undo}}),_c('el-button',{attrs:{"size":"mini","icon":"fal fa-redo"},on:{"click":commands.redo}})],1)],1)]}}])}),_c('editor-menu-bubble',{staticClass:"menububble",attrs:{"editor":_vm.editor},on:{"hide":_vm.hideLinkMenu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var getMarkAttrs = ref.getMarkAttrs;
var menu = ref.menu;
return [_c('div',{staticClass:"menububble",class:{ 'is-active': menu.isActive },style:(("left: " + (menu.left) + "px; bottom: " + (menu.bottom - 80) + "px;"))},[(_vm.linkMenuIsActive)?_c('form',{staticClass:"menububble__form",on:{"submit":function($event){$event.preventDefault();return _vm.setLinkUrl(commands.link, _vm.linkUrl)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.linkUrl),expression:"linkUrl"}],ref:"linkInput",staticClass:"menububble__input",attrs:{"type":"text","placeholder":"https://"},domProps:{"value":(_vm.linkUrl)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.hideLinkMenu($event)},"input":function($event){if($event.target.composing){ return; }_vm.linkUrl=$event.target.value}}}),(isActive.link())?_c('el-button',{attrs:{"size":"mini","icon":"fal fa-trash-alt"},on:{"click":function($event){return _vm.setLinkUrl(commands.link, null)}}}):_c('el-button',{attrs:{"size":"mini","icon":"fal fa-plus"},on:{"click":function($event){return _vm.setLinkUrl(commands.link, _vm.linkUrl)}}})],1):[_c('el-button',{class:{ 'is-active': isActive.link() },attrs:{"size":"mini","icon":"fal fa-link"},on:{"click":function($event){_vm.showLinkMenu(getMarkAttrs('link'))}}},[_vm._v(" "+_vm._s(isActive.link() ? "Link ändern" : "Link hinzufügen")+" ")])]],2)]}}])}),_c('editor-content',{staticClass:"editor__content",attrs:{"editor":_vm.editor}}),_c('input',{ref:"fileInput",staticClass:"file-input",attrs:{"type":"file","accept":"image/png,image/jpeg"},on:{"change":_vm.handleChosenImage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }